import { env, corpId, AgentId } from '@/config';
import netWork from '@/components/Network';
export default {
  name: 'App',
  components: {
    netWork: netWork
  },
  data: function data() {
    return {
      nowState: 'noConnected',
      // 网络状态
      netWorkShow: false
    };
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {
    // 监听网络
    // 网络由异常到正常时触发
    window.addEventListener('online', this.updateOnlineStatus);
    // 网络由正常到异常触发
    window.addEventListener('offline', this.updateOfflineStatus);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOfflineStatus);
  },
  methods: {
    /**
     * @description:ios端输入框初始化软键盘
     * **/
    initIosInput: function initIosInput() {
      // 获取初始信息
      var app = navigator.appVersion;
      //    根据括号进行分割
      var left = app.indexOf('(');
      var right = app.indexOf(')');
      var str = app.substring(left + 1, right);
      console.log('机型1', app);
      var Str = str.split(';');
      var Mobile_Iphone = Str[0];
      localStorage.setItem("Mobile_Iphone", Mobile_Iphone);
      console.log('机型', Mobile_Iphone);
      // ios页面输入框失焦要页面重置到顶部
      if (Mobile_Iphone == 'phone') {
        // 判断是否是ios端
        var flag = false;
        var pageBackNormFunc;
        document.body.addEventListener('focusin', function () {
          // 软键盘弹起事件
          flag = true;
          pageBackNormFunc && clearTimeout(pageBackNormFunc);
        });
        document.body.addEventListener('focusout', function () {
          // 软键盘关闭事件
          if (flag) {
            pageBackNormFunc = setTimeout(function () {
              // // 当键盘收起的时候让页面回到原始位置
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }, 200);
          }
          flag = false;
        });
      }
    },
    /**
     * @description:网络出错-刷新加载页面
     * **/
    refreshNetWork: function refreshNetWork() {
      var onLine = navigator.onLine;
      console.log('onLine', onLine);
      if (onLine) {
        // this.initConfig()
      } else {
        this.$toast({
          message: '暂无网络',
          type: 'error'
        });
      }
    },
    /**
     * @description:监听网络状态
     * **/
    updateOnlineStatus: function updateOnlineStatus() {
      this.netWorkShow = false;
      // this.initConfig()
      console.log('网络连接成功', navigator.onLine);
    },
    updateOfflineStatus: function updateOfflineStatus() {
      this.netWorkShow = true;
      console.log('网络断开连接', navigator.onLine);
    },
    /**
     * @description:初始化监听全局是否有无网络状态
     * @params:{}
     * **/
    init: function init() {
      this.initIosInput();
    }
  }
};