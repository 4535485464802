var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    style: {
      height: "100vh",
      padding: "0px 0 0",
      boxSizing: "border-box",
      background: "#fff;"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.nowState === "noConnected",
      expression: "nowState === 'noConnected'"
    }],
    staticClass: "alwaysloading"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/image/default_img_nointernet.png")
    }
  }), _c("div", [_vm._v("网络未开启，请检查网络设置")]), _c("div", {
    on: {
      click: _vm.refresh
    }
  }, [_vm._v("重新加载")])], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.nowState === "alwaysloading",
      expression: "nowState === 'alwaysloading'"
    }],
    staticClass: "alwaysloading"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/image/default_img_lowinternet.png"),
      mode: "aspectFit"
    }
  }), _c("div", [_vm._v("加载失败，请刷新重试")]), _c("div", {
    on: {
      click: _vm.refresh
    }
  }, [_vm._v("重新加载")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.nowState === "timeout",
      expression: "nowState === 'timeout'"
    }],
    staticClass: "alwaysloading"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/image/default_img_requestAborted.png"),
      mode: "aspectFit"
    }
  }), _c("div", [_vm._v("请求失败,请重试")]), _c("div", {
    staticClass: "btn",
    on: {
      click: _vm.refresh
    }
  }, [_vm._v("重新加载")])], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.nowState === "unusual",
      expression: "nowState === 'unusual'"
    }],
    staticClass: "alwaysloading"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/image/default_img_servererror.png"),
      mode: "aspectFit"
    }
  }), _c("div", [_vm._v("服务器异常,请重试")]), _c("div", {
    on: {
      click: _vm.refresh
    }
  }, [_vm._v("重新加载")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.nowState === "dataEmpty",
      expression: "nowState === 'dataEmpty'"
    }],
    staticClass: "alwaysloading"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/image/default_img_nofind.png"),
      mode: "aspectFit"
    }
  }), _c("div", [_vm._v("暂无数据")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.nowState === "loadRund",
      expression: "nowState === 'loadRund'"
    }],
    staticClass: "alwaysloading"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/image/default_img_lowinternet.png"),
      mode: "aspectFit"
    }
  }), _c("div", [_vm._v("加载中...")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.nowState === "dataGroup",
      expression: "nowState === 'dataGroup'"
    }],
    staticClass: "alwaysloading"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/image/default_img_requestAborted.png"),
      mode: "aspectFit"
    }
  }), _c("div", [_vm._v("此功能暂不支持群聊,敬请期待")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };