export default {
  name: 'Disconnect',
  computed: {
    disconnect: function disconnect() {
      return this.$store.state.app.disconnect;
    }
  },
  mounted: function mounted() {
    // 监听网络
    // 网络由异常到正常时触发
    window.addEventListener('online', this.updateOnlineStatus);
    // 网络由正常到异常触发
    window.addEventListener('offline', this.updateOfflineStatus);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOfflineStatus);
  },
  methods: {
    updateOnlineStatus: function updateOnlineStatus() {
      this.$store.commit('app/SET_DISCONNECT', false);
      this.refresh();
    },
    updateOfflineStatus: function updateOfflineStatus() {
      this.$store.commit('app/SET_DISCONNECT', 'noConnected');
    },
    refresh: function refresh() {
      this.$emit('refresh');
    }
  }
};