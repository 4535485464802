export default {
  props: {
    nowState: {
      default: '',
      type: String
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    refresh: function refresh() {
      this.$emit('refresh', 0);
    }
  }
};