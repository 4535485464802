// 本地环境配置
// module.exports = {
//   env: 'dev',
//   corpId: 'wwce03f97db3c60070',
//   AgentId: '1000061',
//   Secret: 'aBonW9gZFz0WgxHj-71V7r-PuP8AZfg1eZk0UcQ8wBI',
//   title: 'SCRM',
//   baseUrl: 'https://dspmicrouag.shupian.cn/',
//   baseApi: 'https://dspmicrouag.shupian.cn/crm-middle-scrm/', // -中间层-本地api请求地址,注意：如果你使用了代理，请设置成'/'
//   authApi: 'https://dspmicrouag.shupian.cn/crisps-auth-center-api-v2/', //鉴权地址-请求商户中心登录信息
//   sysCode: 'crisps-qds-crm',
//   addressUrl: 'https://dm-crm.shupian.cn/', //不同环境的域名
//   uploadUrl: 'http://xgxg.shupian.cn/', //不同环境的域名
//   deleteUrl: 'https://dspmicrouag.shupian.cn/', //不同环境的域名
//   $cdn: '',
//   buriedEnv:'D',//埋点对应环境
//   buriedScrect:'0025a2facf2b013933adada59d5fb115',//埋点对应screct
// }
// aBonW9gZFz0WgxHj-71V7r-PuP8AZfg1eZk0UcQ8wBI

// 本地环境配置
module.exports = {
  env: 'dev',
  corpId: 'wwce03f97db3c60070',
  AgentId: '1000065',
  //T 环境-应用ID
  Secret: '527266a46c2e2513f23e4394d21925ac',
  LOGIN_SECRET: '5f90d0d3e8c9ee49ba09f0e1595b89ed',
  yxwlSysCode: 'crisps-materiel-b-node',
  //营销物料
  yxwlScrect: '90bd0fc13d48d2d6c7acc8c6460ed2ab',
  //营销物料
  title: 'SCRM',
  pcBaseApi: 'https://dspmicrouag.shupian.cn/',
  baseUrl: 'https://dspmicrouag.shupian.cn/',
  baseApi: 'https://dspmicrouag.shupian.cn/crm-middle-scrm/',
  // -中间层-本地api请求地址,注意：如果你使用了代理，请设置成'/'
  authApi: 'https://dspmicrouag.shupian.cn/crisps-auth-center-api-v2/',
  //鉴权地址-请求商户中心登录信息
  sysCode: 'crisps-crm',
  LOGIN_SYS_CODE: 'crisps-sys',
  addressUrl: 'https://dm-crm.shupian.cn/',
  //不同环境的域名
  appDownloadUrl: 'https://dm.shupian.cn/',
  //不同环境的域名
  uploadUrl: 'http://xgxg.shupian.cn/',
  //不同环境的域名
  deleteUrl: 'https://dspmicrouag.shupian.cn/',
  //不同环境的域名
  $cdn: '',
  buriedEnv: 'D',
  //埋点对应环境
  yxwlAddressShareUrl: 'https://dm.shupian.cn/',
  authUrl: 'https://dm-crm.shupian.cn',
  buriedScrect: '0025a2facf2b013933adada59d5fb115',
  //埋点对应screct
  kjscUrl: 'https://tggypapply.shupian.cn/',
  //会计管理
  fileUploadSecret: 'd80cad8d9e006267057e30365a49e336' //文件中心上传组件的secret
};
// aBonW9gZFz0WgxHj-71V7r-PuP8AZfg1eZk0UcQ8wBI