var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.netWorkShow,
      expression: "netWorkShow"
    }],
    staticClass: "net-work"
  }, [_c("netWork", {
    ref: "netWork",
    attrs: {
      nowState: _vm.nowState
    },
    on: {
      refresh: _vm.refreshNetWork
    }
  })], 1), !_vm.netWorkShow ? _c("div", {
    staticClass: "data-app"
  }, [_c("keep-alive", [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()], 1), !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };