export default {
  state: {
    path: undefined,
    //路由
    corpId: undefined,
    //参数
    AgentId: undefined //标识
  },
  mutations: {
    saveTestActive: function saveTestActive(state, value) {
      state.active = value;
    },
    //存储跳转路径和参数
    savePath: function savePath(state, value) {
      // state.path = value.path
      state.corpId = value.corpId;
      state.AgentId = value.AgentId;
    }
  }
};