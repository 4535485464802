import "core-js/modules/es.object.to-string.js";
import { getUserId } from "./init";
import router from '@/router';
/**
 * @description:获取入口环境
 * **/

export var getEntranceUtils = {
  /**获取入口环境**/getContext: function getContext() {
    var entry, shareTicket;
    return new Promise(function (resolve) {
      wx.invoke('getContext', {}, function (res) {
        if (res.err_msg == "getContext:ok") {
          entry = res.entry; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment

          //一对一单聊
          if (res.entry == 'single_chat_tools') {
            console.log('商机一对一单聊');
          }
          //群聊会话
          else if (res.entry == 'group_chat_tools') {
            console.log('商机群聊会话进入');
          }

          //会话的聊天附件栏进入
          else if (res.entry == 'chat_attachment') {
            console.log('商机会话聊天附件栏');
          }
          shareTicket = res.shareTicket; //可用于调用getShareInfo接口
          resolve(res);
        } else {
          //错误处理
        }
      });
    });
  }
};