import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from 'vue';
import showTips from './showTips';
import windowResize from './windowResize';
import toolTip from './toolTip';

// 自定义指令
var directives = {
  toolTip: toolTip,
  showTips: showTips,
  windowResize: windowResize
};
export default {
  install: function install(Vue) {
    Object.keys(directives).forEach(function (key) {
      Vue.directive(key, directives[key]);
    });
  }
};