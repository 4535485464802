import "core-js/modules/es.array.concat.js";
import Vue from 'vue';
import { deviceLogin } from '@/utils/marketValid';
/**
 * @description:文字超长显示省略号-鼠标移入显示悬浮弹窗
 * @author:T
 * @time：
 * **/
var showTips = {
  inserted: function inserted(el) {
    var dictionLogin = deviceLogin(); //true-电脑登录,false-手机登录
    var curStyle = window.getComputedStyle(el, ''); // 获取当前元素的style
    var textSpan = document.createElement('span'); // 创建一个容器来记录文字的width
    // 设置新容器的字体样式，确保与当前需要隐藏的样式相同
    textSpan.style.fontSize = curStyle.fontSize;
    textSpan.style.fontWeight = curStyle.fontWeight;
    textSpan.style.fontFamily = curStyle.fontFamily;
    // 将容器插入body，如果不插入，offsetWidth为0
    document.body.appendChild(textSpan);
    // 设置新容器的文字
    textSpan.innerHTML = el.innerText;
    if (dictionLogin) {
      // 如果字体元素大于当前元素，则需要隐藏
      if (textSpan.offsetWidth > el.offsetWidth) {
        // 给当前元素设置超出隐藏
        el.style.overflow = 'hidden';
        el.style.textOverflow = 'ellipsis';
        el.style.whiteSpace = 'nowrap';
        var widHeight = document.documentElement.clientHeight;
        var moveX = 0; //提示框展示位置

        // 鼠标移入
        el.onmouseenter = function (e) {
          var widWidth = document.documentElement.clientWidth;
          if (e.clientX + 100 >= widWidth) {
            moveX = e.clientX - 100;
          } else {
            moveX = e.clientX;
          }
          // 创建浮层元素并设置样式
          var vcTooltipDom = document.createElement('div');
          vcTooltipDom.style.cssText = "\n          max-width:200px;\n          max-height: 800px;\n          overflow: auto;\n          position:absolute;\n          top:".concat(e.clientY + 5, "px;\n          left:").concat(moveX, "px;\n          background: rgba(0, 0 , 0, .6);\n          color:#fff;\n          border-radius:5px;\n          padding:10px;\n          display:inline-block;\n          font-size:12px;\n          word-wrap:break-word;\n          word-break:break-all;\n          z-index:19999\n        ");
          // 设置id方便寻找
          vcTooltipDom.setAttribute('id', 'vc-tooltip');
          // 将浮层插入到body中
          document.body.appendChild(vcTooltipDom);
          // 浮层中的文字
          document.getElementById('vc-tooltip').innerHTML = el.innerText;
        };
        // 鼠标移出
        el.onmouseleave = function () {
          // 找到浮层元素并移出
          var vcTooltipDom = document.getElementById('vc-tooltip');
          vcTooltipDom && document.body.removeChild(vcTooltipDom);
        };
      }
    }

    // 记得移除刚刚创建的记录文字的容器
    document.body.removeChild(textSpan);
  },
  // 指令与元素解绑时
  unbind: function unbind() {
    // 找到浮层元素并移除
    var vcTooltipDom = document.getElementById('vc-tooltip');
    vcTooltipDom && document.body.removeChild(vcTooltipDom);
  }
};
export default showTips;