// 本地环境配置
module.exports = {
  env: 'production',
  corpId: 'wwce03f97db3c60070',
  AgentId: '1000065',
  //T 环境-应用ID
  Secret: 'ea052a6c8162443b6f8ac673f32e8376',
  LOGIN_SECRET: 'HtAxsrxtpjiEHripEP5YpZTrcZieYZJP',
  LOGIN_SYS_CODE: 'crisps-sys',
  yxwlSysCode: 'crisps-materiel-b-node',
  //营销物料
  yxwlScrect: 'e14b3f54414a3fc7f41f821184064c07',
  //营销物料
  pcBaseApi: 'https://spmicrouag.shupian.cn/',
  //pc中间层
  title: 'SCRM',
  baseUrl: '',
  baseApi: 'https://spmicrouag.shupian.cn/crm-middle-scrm/',
  // -中间层-本地api请求地址,注意：如果你使用了代理，请设置成'/'
  authApi: 'https://spmicrouag.shupian.cn/crisps-auth-center-api-v2/',
  //鉴权地址-请求商户中心登录信息
  sysCode: 'crisps-crm',
  addressUrl: 'https://m-crm.shupian.net/',
  //不同环境的域名
  appDownloadUrl: 'https://m.shupian.cn/',
  //不同环境的域名
  uploadUrl: 'http://xgxg.shupian.cn/',
  //不同环境的域名
  deleteUrl: 'https://spmicrouag.shupian.cn/',
  //不同环境的域名
  $cdn: '',
  buriedEnv: 'P',
  //埋点对应环境
  buriedScrect: 'b64389e4f33484d81e13d76fb5e8aab8',
  //埋点对应screct
  authUrl: 'https://m-crm.shupian.net',
  contractShareUrl: 'https://m.shupian.cn/',
  //0802新增合同分享跳转地址
  wxAppId: 'wxf79340c79c71e3f0',
  //C端-微信小程序id
  mpAppId: 'wx73d2f2d92b0e429b',
  //B端-智能名片小程序id
  yxwlAddressShareUrl: 'https://m.shupian.cn/',
  //营销物料模块-分享h5地址
  productShareUrl: 'https://ms.shupian.cn/#/',
  //生产单-分享对应的不同环境的地址
  kjscUrl: 'https://ggypapply.shupian.cn/',
  //会计管理
  applicationMainPcUrl: 'https://bpmn.shupian.cn/#/apply/waitList',
  //应用主页-对应pc入口
  applicationMainAPPUrl: 'https://mobie-bpmn.shupian.cn/#/pages/index/main',
  //应用主页-对应app入口
  mchDetailId: '773193036932912759',
  fileUploadSecret: 'd016c840d18db03c0deb0afe15e353db' //文件中心上传组件的secret
};
// aBonW9gZFz0WgxHj-71V7r-PuP8AZfg1eZk0UcQ8wBI