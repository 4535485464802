import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * 基础路由
 * @type { *[] }
 */
export var constantRouterMap = [{
  path: '/test',
  component: function component() {
    return import('@/views/test-api/test-api.vue');
  },
  meta: {
    title: 'test-api',
    keepAlive: false
  },
  children: []
}, {
  path: '/main',
  component: function component() {
    return import('@/views/login/main.vue');
  },
  meta: {
    title: '主界面',
    keepAlive: false
  },
  children: []
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/login.vue');
  },
  meta: {
    title: '客户资料-授权登录',
    keepAlive: false
  },
  children: []
}, {
  path: '/marketLogin',
  component: function component() {
    return import('@/views/login/marketLogin.vue');
  },
  meta: {
    title: '营销素材-授权登录',
    keepAlive: false
  },
  children: []
}, {
  path: '/customer-information',
  name: 'CustomerInformation',
  component: function component() {
    return import('@/views/customer-information/newModel/index.vue');
  },
  meta: {
    title: '客户资料',
    keepAlive: false
  }
}, {
  path: '/group-customers-information',
  name: 'GroupCustomersInformation',
  component: function component() {
    return import('@/views/group-customers-information/index.vue');
  },
  meta: {
    title: '群客户资料',
    keepAlive: false
  }
}, {
  path: '/add-business',
  name: 'AddBusiness',
  component: function component() {
    return import('@/views/add-business/index.vue');
  },
  meta: {
    title: '录入商机',
    keepAlive: false
  }
}, {
  path: '/select-company',
  name: 'SelectCompany',
  component: function component() {
    return import('@/components/selectCompany.vue');
  },
  meta: {
    title: '公司名称',
    keepAlive: false
  }
}, {
  path: '/add-phone',
  name: 'AddPhone',
  component: function component() {
    return import('@/views/add-phone/index.vue');
  },
  meta: {
    title: '录入手机号',
    keepAlive: false
  }
}, {
  path: '/add-demand',
  name: 'AddDemand',
  component: function component() {
    return import('@/views/add-demand/index.vue');
  },
  meta: {
    title: '新增需求',
    keepAlive: false
  }
}, {
  path: '/home',
  name: 'Home',
  component: function component() {
    return import('@/views/home/index');
  },
  meta: {
    title: '首页',
    keepAlive: false
  }
}, {
  path: '/market',
  name: 'market',
  component: function component() {
    return import('@/views/market/index');
  },
  meta: {
    title: '营销中心',
    keepAlive: true
  }
}, {
  path: '/articleInfo',
  name: 'articleInfo',
  component: function component() {
    return import('@/views/market/page/articleInfo');
  },
  meta: {
    title: '文章详情',
    keepAlive: false
  }
}, {
  path: '/videoInfo',
  name: 'videoInfo',
  component: function component() {
    return import('@/views/market/page/videoInfo');
  },
  meta: {
    title: '视频详情',
    keepAlive: false
  }
}, {
  path: '/qsInfo',
  name: 'qsInfo',
  component: function component() {
    return import('@/views/market/page/qsInfo');
  },
  meta: {
    title: '问答详情',
    keepAlive: false
  }
}, {
  path: '/askInfo',
  name: 'askInfo',
  component: function component() {
    return import('@/views/market/page/askInfo');
  },
  meta: {
    title: '我的提问-问答详情',
    keepAlive: false
  }
}, {
  path: '/write-follow',
  name: 'WriteFollow',
  component: function component() {
    return import('@/views/write-follow/index.vue');
  },
  meta: {
    title: '写跟进',
    keepAlive: true
  }
}, {
  path: '/phrase-page',
  name: 'phrasePage',
  component: function component() {
    return import('@/views/write-follow/page/phrase-page.vue');
  },
  meta: {
    title: '我的常用语',
    keepAlive: false
  }
}, {
  path: '/phrase-group',
  name: 'phraseGroup',
  component: function component() {
    return import('@/views/write-follow/page/phrase-group.vue');
  },
  meta: {
    title: '设置分组',
    keepAlive: false
  }
}, {
  path: '/more-question',
  name: 'moreQuestion',
  component: function component() {
    return import('@/views/market/page/moreQuestion.vue');
  },
  meta: {
    title: '更多问答',
    keepAlive: false
  }
}, {
  path: '/no-jurisdiction',
  name: 'noJurisdiction',
  component: function component() {
    return import('@/views/customer-information/components/no-jurisdiction');
  },
  meta: {
    title: '无权限访问',
    keepAlive: false
  }
},
/*
 * 0714-新增商机线索模块
 * */

{
  path: '/business-clues',
  name: 'businessClues',
  component: function component() {
    return import('@/views/businessClues');
  },
  meta: {
    title: '商机线索',
    keepAlive: false,
    rank: 1
  }
}, {
  path: '/business-search',
  name: 'businessSearch',
  component: function component() {
    return import('@/views/businessClues/business/page/searchPage');
  },
  meta: {
    title: '商机搜索',
    keepAlive: false
  }
}, {
  path: '/clues-lz-info',
  name: 'cluesLzInfo',
  component: function component() {
    return import('@/views/businessClues/clues/page/cluesLzInfo');
  },
  meta: {
    title: '留资线索详情',
    keepAlive: false
  }
}, {
  path: '/clues-txb-info',
  name: 'cluesTxbInfo',
  component: function component() {
    return import('@/views/businessClues/clues/page/cluesTxbInfo');
  },
  meta: {
    title: '探索宝详情',
    keepAlive: false
  }
}, {
  path: '/clues-search',
  name: 'cluesSearCh',
  component: function component() {
    return import('@/views/businessClues/clues/page/cluesSearchPage');
  },
  meta: {
    title: '线索搜索界面',
    keepAlive: false
  }
}, {
  path: '/invid-page',
  name: 'invidPage',
  component: function component() {
    return import('@/views/businessClues/clues/page/invidPage');
  },
  meta: {
    title: '无效线索',
    keepAlive: false
  }
}, {
  path: '/customer-info',
  name: 'customerInfo',
  component: function component() {
    return import('@/views/businessClues/customer-info/page/customerInfo.vue');
  },
  meta: {
    title: '客户详情',
    keepAlive: false
  }
}, {
  path: '/business-info',
  name: 'businessInfo',
  component: function component() {
    return import('@/views/customer-information/page/businessInfo.vue');
  },
  meta: {
    title: '商机详情',
    keepAlive: false
  }
},
// 企业购权益-详情页
{
  path: '/member-detail',
  name: 'MemberDetail',
  component: function component() {
    return import('@/views/customer-information/components/member-interest/components/memberDetail.vue');
  },
  meta: {
    title: 'SKU详情页',
    keepAlive: false
  }
}, {
  path: '/add-phone-plus',
  name: 'AddPhonePlus',
  component: function component() {
    return import('@/views/add-phone/addPhone.vue');
  },
  meta: {
    title: '添加手机号',
    keepAlive: false
  }
}, /**/

{
  path: '/word-library',
  name: 'wordLibrary',
  component: function component() {
    return import('@/views/word-library/index.vue');
  },
  meta: {
    title: '话术库',
    keepAlive: false
  }
}, {
  path: '/add-word-library',
  name: 'addWordLibrary',
  component: function component() {
    return import('@/views/word-library/page/addPersonWord');
  },
  meta: {
    title: '新增话术',
    keepAlive: false
  }
}, {
  path: '/edit-word-library',
  name: 'editWordLibrary',
  component: function component() {
    return import('@/views/word-library/page/editPersonWord');
  },
  meta: {
    title: '编辑话术',
    keepAlive: false
  }
}, {
  path: '/videoPage',
  name: 'videoPage',
  component: function component() {
    return import('@/views/word-library/page/videoPage');
  },
  meta: {
    title: '视频详情',
    keepAlive: false
  }
}, /** 测试分享订单-合同**/
{
  path: '/share-test',
  name: 'shareTest',
  component: function component() {
    return import('@/views/friends/shareTest');
  },
  meta: {
    title: '分享推单',
    keepAlive: false
  }
}, {
  path: '/ifream-test',
  name: 'ifream',
  component: function component() {
    return import('@/views/friends/index');
  },
  meta: {
    title: 'ifream嵌套',
    keepAlive: false
  }
}, /** 测试结束**/
{
  path: '/order',
  name: 'order',
  component: function component() {
    return import('@/views/order/index');
  },
  meta: {
    title: '订单推送',
    keepAlive: false
  }
}, /** 20220908新增推广资源企微消息跳转到商机详情页面**/
{
  path: '/biz-recourse',
  name: 'bizRecourse',
  component: function component() {
    return import('@/views/bizRecourse/index');
  },
  meta: {
    title: '商机详情',
    keepAlive: false
  }
}, {
  path: '/abnnormal-page',
  name: 'abnnormalPage',
  component: function component() {
    return import('@/views/bizRecourse/page/abnnormal');
  },
  meta: {
    title: '异常数据',
    keepAlive: false
  }
}, /** 20221227新增天天分享-新闻资讯-生产单页面**/
{
  path: '/market-news',
  name: 'newsInfo',
  component: function component() {
    return import('@/views/market/news/page/newsInfo');
  },
  meta: {
    title: '新闻资讯详情',
    keepAlive: false
  }
},
//
{
  path: '/producTion-list',
  name: 'producTionList',
  component: function component() {
    return import('@/views/order/productionOrder/productOrder');
  },
  meta: {
    title: '生产单列表',
    keepAlive: false
  }
}, {
  path: '/product-info',
  name: 'productInfo',
  component: function component() {
    return import('@/views/order/productionOrder/components/productInfo');
  },
  meta: {
    title: '生产单详情',
    keepAlive: false
  }
},
// 2023908 生产管理页
{
  path: '/production-manage',
  name: 'productionManage',
  component: function component() {
    return import('@/views/production-manage/index.vue');
  },
  meta: {
    title: '生产管理',
    keepAlive: false
  }
},
// 2023908 生产管理搜索页
{
  path: '/production-search',
  name: 'productionSearch',
  component: function component() {
    return import('@/views/production-manage/components/production-search.vue');
  },
  meta: {
    title: '生产管理',
    keepAlive: false
  }
}, /** 20230105新增应用主页**/
{
  path: '/application-main',
  name: 'applicationMain',
  component: function component() {
    return import('@/views/applicationMain/appMain');
  },
  meta: {
    title: '应用主页',
    keepAlive: false
  }
},
// 职能转介绍，录入转介绍页面-应用主页
{
  path: '/introduce-form',
  name: 'introduceForm',
  component: function component() {
    return import('@/views/applicationMain/components/introduceForm');
  },
  meta: {
    title: '录入转介绍',
    keepAlive: false
  }
},
// 职能转介绍-侧边栏
{
  path: '/interior-introduce',
  name: 'interiorIntroduce',
  component: function component() {
    return import('@/views/interior-introduce/index.vue');
  },
  meta: {
    title: '职能转介绍',
    keepAlive: true
  }
},
// 职能转介绍-服务详情-侧边栏
{
  path: '/introduce-detail',
  name: 'introduceDetail',
  component: function component() {
    return import('@/views/interior-introduce/components/introduce-detail');
  },
  meta: {
    title: '服务详情',
    keepAlive: false
  }
},
// 职能转介绍-弹窗
{
  path: '/interior-introduce-window',
  name: 'interiorIntroduceWindow',
  component: function component() {
    return import('@/views/interior-introduce-window/index.vue');
  },
  meta: {
    title: '职能转介绍',
    keepAlive: true
  }
},
// 职能转介绍-服务详情-弹窗
{
  path: '/introduce-detail-window',
  name: 'introduceDetailWindow',
  component: function component() {
    return import('@/views/interior-introduce-window/components/introduce-detail');
  },
  meta: {
    title: '服务详情',
    keepAlive: false
  }
},
// {
//   path: '/test-new',
//   name: 'testNew',
//   component: () => import('@/views/market/testnew'),
//   meta: { title: '异常数据', keepAlive: false }
// },
{
  path: '/addClue',
  name: 'AddClue',
  component: function component() {
    return import('@/views/businessClues/clues/page/add-clue/index.vue');
  },
  meta: {
    title: '录入线索',
    keepAlive: true,
    rank: 2
  }
}, /** 20230225新增移交记录**/
{
  path: '/hand-over',
  name: 'handOver',
  component: function component() {
    return import('@/views/handOver/index');
  },
  meta: {
    title: '移交记录',
    keepAlive: false
  }
},
// 307 智能名片-个人名片页
{
  path: '/personal-card',
  name: 'personalCard',
  component: function component() {
    return import('@/views/personal-card/index');
  },
  meta: {
    title: '个人名片',
    keepAlive: true
  }
},
//  413新增群-群生产单
{
  path: '/group-product-list',
  name: 'groupProductList',
  component: function component() {
    return import('@/views/group-customers-information/page/productionList');
  },
  meta: {
    title: '群生产单列表',
    keepAlive: false
  }
}, {
  path: '/group-product-info',
  name: 'groupProductInfo',
  component: function component() {
    return import('@/views/group-customers-information/page/productionInfo');
  },
  meta: {
    title: '群生产单详情',
    keepAlive: false
  }
}, {
  path: '/customer-evaluation',
  name: 'customerEvaluation',
  component: function component() {
    return import('@/views/customer-evaluation/index');
  },
  meta: {
    title: '客户评价',
    keepAlive: false
  }
}, {
  path: '/exist-role',
  name: 'existRolePage',
  component: function component() {
    return import('@/components/existRolePage');
  },
  meta: {
    title: '公共页面-无角色',
    keepAlive: false
  }
},
// 客户查询
{
  path: '/customer-query',
  name: 'customerQuery',
  component: function component() {
    return import('@/views/customer-query');
  },
  meta: {
    title: '客户查询',
    keepAlive: false
  }
},
//  面谈中心-二维码界面
{
  path: '/interview-center-sign',
  name: 'interviewCenterSign',
  component: function component() {
    return import('@/views/interviewCenter');
  },
  meta: {
    title: '面谈中心',
    keepAlive: false
  }
},
// 商务通接入录入客源表单
{
  path: '/add-source',
  name: 'AddSource',
  component: function component() {
    return import('@/views/add-source/index.vue');
  },
  meta: {
    title: '录入客源',
    keepAlive: false
  }
},
// 美洽接入录入客源表单
{
  path: '/add-customer',
  name: 'addCustomer',
  component: function component() {
    return import('@/views/add-customer/index.vue');
  },
  meta: {
    title: '录入客源',
    keepAlive: false
  }
},
//录入线索-线索商机合并
{
  path: '/add-clue',
  name: 'AddClueSource',
  component: function component() {
    return import('@/views/add-business/addClue/addClue.vue');
  },
  meta: {
    title: '录入线索',
    keepAlive: false
  }
},
//  客户穿透审批
{
  path: '/customer-approve',
  name: 'customerApprove',
  component: function component() {
    return import('@/views/customerApprove/index');
  },
  meta: {
    title: '客户穿透审批',
    keepAlive: false
  }
},
//  客户穿透审批

//  一键拉群
{
  path: '/crawed-group',
  name: 'crawedGroup',
  component: function component() {
    return import('@/views/crawedGroup/index');
  },
  meta: {
    title: '一键拉群',
    keepAlive: false
  }
},
//  搜索私客列表
{
  path: '/search-biz-list',
  name: 'searchBizList',
  component: function component() {
    return import('@/views/customer-information/newModel/page/list/searchBizList.vue');
  },
  meta: {
    title: '搜索私客列表',
    keepAlive: false
  }
},
//  私客详情
{
  path: '/personal-details',
  name: 'personalDetails',
  component: function component() {
    return import('@/views/customer-information/newModel/page/personal/personalDetails.vue');
  },
  meta: {
    title: '私客详情',
    keepAlive: false
  }
},
//  私客--》商机详情
{
  path: '/biz-info',
  name: 'bizInfo',
  component: function component() {
    return import('@/views/customer-information/newModel/page/business/businessInfo.vue');
  },
  meta: {
    title: '商机详情',
    keepAlive: false
  }
},
//关联商机
{
  path: '/relevance-biz',
  name: 'relevanceBiz',
  component: function component() {
    return import('@/views/customer-information/newModel/page/business/relevanceBiz.vue');
  },
  meta: {
    title: '关联商机',
    keepAlive: false
  }
},
//跟进记录
{
  path: '/follow-record',
  name: 'followRecord',
  component: function component() {
    return import('@/views/followRecord/index.vue');
  },
  meta: {
    title: '跟进记录',
    keepAlive: false
  }
},
//企业弹窗模块-开始
{
  path: '/customer-approve',
  name: 'customerApprove',
  component: function component() {
    return import('@/views/customerApprove/index');
  },
  meta: {
    title: '客户穿透审批弹窗',
    keepAlive: false
  }
}, {
  path: '/pc-private-details',
  name: 'rescourceDetails',
  component: function component() {
    return import('@/views/pcDialog/rescourceDetails/index.vue');
  },
  meta: {
    title: '资源详情弹窗',
    keepAlive: false
  }
}, {
  path: '/update-group-personal',
  name: 'updateGroupPersonal',
  component: function component() {
    return import('@/views/pcDialog/updateGroupPersonal/index.vue');
  },
  meta: {
    title: '更新群成员弹窗',
    keepAlive: false
  }
},
//end

{
  path: '/empty-page',
  name: 'emptyPage',
  component: function component() {
    return import('@/views/businessClues/empty-page/index');
  },
  meta: {
    title: '客户管理-无权限状态',
    keepAlive: false
  }
}, {
  path: '/error-tips-page',
  name: 'errorTipsPage',
  component: function component() {
    return import('@/components/page/errorTipsPage');
  },
  meta: {
    title: '公共-错误信息提示页面',
    keepAlive: false
  }
}, {
  path: '/check-invite',
  name: 'checkInvite',
  component: function component() {
    return import('@/views/pcDialog/checkInvite/index');
  },
  meta: {
    title: '邀约面谈审批',
    keepAlive: false
  }
}, {
  path: '/update-group-personal',
  name: 'updateGroupPersonal',
  component: function component() {
    return import('@/views/pcDialog/updateGroupPersonal/index.vue');
  },
  meta: {
    title: '更新群成员',
    keepAlive: false
  }
}, {
  path: '/digestion-code',
  name: 'digestionCode',
  component: function component() {
    return import('@/views/pcDialog/digestionCode/index.vue');
  },
  meta: {
    title: '共享消化客户群',
    keepAlive: false
  }
},
// 会计生产
{
  path: '/customer-admin',
  name: 'customerAdmin',
  component: function component() {
    return import('@/views/kjsc/customerAdmin/index.vue');
  },
  meta: {
    title: '关联客户',
    keepAlive: false
  }
},
// 聊天记录
{
  path: '/history-records',
  name: 'historyRecords',
  component: function component() {
    return import('@/views/historyRecords/index.vue');
  },
  meta: {
    title: '群聊记录',
    keepAlive: false
  }
}, {
  path: '/auto-group',
  name: 'autoGroup',
  component: function component() {
    return import('@/views/autoGroup/index');
  },
  meta: {
    title: '自动拉群成员',
    keepAlive: false
  }
}, {
  path: '/scrm-login',
  name: 'scrm-login',
  component: function component() {
    return import('@/views/tranfer/login');
  },
  meta: {
    title: '登录中转页',
    keepAlive: false
  }
}];