import api from './index';
// 根据环境不同引入不同api地址
import { authApi, merchantApi } from '@/config';
// axios
import request from '@/utils/requestLogin';

// 登录
export function login(data) {
  return request({
    url: authApi + 'nk/auth/v1/login.do',
    method: 'post',
    data: data
  });
}