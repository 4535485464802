import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import Vue from 'vue';

/**
 * @description:文字超长显示省略号-鼠标移入显示悬浮弹窗
 * @author:T
 * @time：
 * **/
var toolTip = {
  bind: function bind(el, binding) {
    if (binding.expression) return; //如果v-tool-tip上绑定了表达式则返回
    var curStyle = window.getComputedStyle(el, ''); // 获取当前元素的style
    var textSpan = document.createElement('div'); // 创建一个容器来记录文字的width
    textSpan.className = 'tool-tip';

    // 设置新容器的字体样式，确保与当前需要隐藏的样式相同
    textSpan.style.fontSize = curStyle.fontSize;
    textSpan.style.fontWeight = curStyle.fontWeight;
    textSpan.style.fontFamily = curStyle.fontFamily;
    // 将容器插入body，如果不插入，offsetWidth为0
    document.body.appendChild(textSpan);
    // 设置新容器的文字
    textSpan.innerHTML = el.innerText;

    // 如果字体元素大于当前元素，则需要隐藏
    if (textSpan.offsetWidth > el.offsetWidth) {
      // 给当前元素设置超出隐藏
      el.style.overflow = 'hidden';
      el.style.textOverflow = 'ellipsis';
      el.style.whiteSpace = 'nowrap';
      // 鼠标移入
      el.onmouseenter = function (e) {
        // console.log('鼠标位置',e.clientY,e.clientX,document.body.clientWidth)
        var bodyWidth = document.body.clientWidth;
        var moveX;
        if (Number(bodyWidth) - Number(e.clientX) > 80) {
          moveX = e.clientX;
        } else {
          moveX = Number(e.clientX) - 200;
        }

        // 创建浮层元素并设置样式
        var vcTooltipDom = document.createElement('div');
        vcTooltipDom.style.cssText = "\n          max-width:450px;\n          max-height: 400px;\n          overflow: auto;\n          position:fixed;\n          word-wrap: break-word;\n          word-break: normal;\n          top:".concat(e.clientY + 5, "px;\n          left:").concat(moveX, "px;\n          background: rgba(0, 0 , 0, .6);\n          color:#fff;\n          border-radius:5px;\n          padding:10px;\n          display:inline-block;\n          font-size:12px;\n          z-index:999999;\n          word-break: break-all; \n          word-wrap: break-word;\n           white-space: pre-wrap; \n        ");
        // 设置id方便寻找
        vcTooltipDom.setAttribute('id', 'vc-tooltip2');
        // 将浮层插入到body中
        document.body.appendChild(vcTooltipDom);
        // 浮层中的文字
        document.getElementById('vc-tooltip2').innerHTML = el.innerText;
      };
      // 鼠标移出
      el.onmouseleave = function () {
        // 找到浮层元素并移出
        var vcTooltipDom = document.getElementById('vc-tooltip2');
        vcTooltipDom && document.body.removeChild(vcTooltipDom);
      };
    }
    // 记得移除刚刚创建的记录文字的容器
    document.body.removeChild(textSpan);
  },
  componentUpdated: function componentUpdated(el, binding) {
    if (!binding.value) return; //如果v-tool-tip绑定值没有参数则返回

    var curStyle = window.getComputedStyle(el, ''); // 获取当前元素的style
    var textSpan = document.createElement('div'); // 创建一个容器来记录文字的width
    textSpan.className = 'tool-tip';

    // 设置新容器的字体样式，确保与当前需要隐藏的样式相同
    textSpan.style.fontSize = curStyle.fontSize;
    textSpan.style.fontWeight = curStyle.fontWeight;
    textSpan.style.fontFamily = curStyle.fontFamily;
    // 将容器插入body，如果不插入，offsetWidth为0
    document.body.appendChild(textSpan);
    // 设置新容器的文字
    textSpan.innerHTML = el.innerText;

    // 如果字体元素大于当前元素，则需要隐藏
    if (textSpan.offsetWidth > el.offsetWidth) {
      // 给当前元素设置超出隐藏
      el.style.overflow = 'hidden';
      el.style.textOverflow = 'ellipsis';
      // el.style.whiteSpace = 'nowrap'
      // 鼠标移入
      el.onmouseenter = function (e) {
        // console.log('鼠标位置',e.clientY,e.clientX,document.body.clientWidth)
        var bodyWidth = document.body.clientWidth;
        var moveX;
        if (Number(bodyWidth) - Number(e.clientX) > 80) {
          moveX = e.clientX;
        } else {
          moveX = Number(e.clientX) - 200;
        }
        // 创建浮层元素并设置样式
        var vcTooltipDom = document.createElement('div');
        vcTooltipDom.style.cssText = "\n          max-width:450px;\n          max-height: 400px;\n          overflow: auto;\n          position:fixed;\n          word-wrap: break-word;\n          word-break: normal;\n          top:".concat(e.clientY + 5, "px;\n          left:").concat(moveX, "px;\n          background: rgba(0, 0 , 0, .6);\n          color:#fff;\n          border-radius:5px;\n          padding:10px;\n          display:inline-block;\n          font-size:12px;\n          z-index:999999;\n          word-break: break-all; \n          word-wrap: break-word;\n           white-space: pre-wrap; \n        ");
        console.log(binding, '11111111111111111');
        // 设置id方便寻找
        vcTooltipDom.setAttribute('id', 'vc-tooltip2');
        // 将浮层插入到body中
        document.body.appendChild(vcTooltipDom);
        // 浮层中的文字
        // if(binding.value) {
        document.getElementById('vc-tooltip2').innerHTML = binding.value;
        // } else {
        //   document.getElementById('vc-tooltip2').innerHTML = el.innerText
        // }
      };
      // 鼠标移出
      el.onmouseleave = function () {
        // 找到浮层元素并移出
        var vcTooltipDom = document.getElementById('vc-tooltip2');
        vcTooltipDom && document.body.removeChild(vcTooltipDom);
      };
    }
    // 记得移除刚刚创建的记录文字的容器
    document.body.removeChild(textSpan);
  },
  // 指令与元素解绑时
  unbind: function unbind() {
    // 找到浮层元素并移除
    var vcTooltipDom = document.getElementById('vc-tooltip2');
    vcTooltipDom && document.body.removeChild(vcTooltipDom);
  }
};
export default toolTip;