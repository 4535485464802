import _objectSpread from "/data/shupian/qds-scrmnet-0/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import axios from 'axios';
import store from '@/store';
import { sign } from '@fe/common';
// 根据环境不同引入不同api地址
import { baseApi, sysCode, LOGIN_SYS_CODE, LOGIN_SECRET } from '@/config';
// create an axios instance
var service = axios.create({
  baseURL: baseApi,
  // url = base api url + request url
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 5000 // request timeout
});

// request拦截器 request interceptor
service.interceptors.request.use(function (config) {
  // 不传递默认开启loading
  if (!config.hideloading) {
    // loading
    _Toast.loading({
      forbidClick: true
    });
  }
  // if (store.getters.token) {
  //   config.headers['X-Token'] = ''
  // }
  // 请求头不需要签名的参数放这里
  var mchInfo = localStorage.getItem('mchInfo'); // 商户信息
  var signData = sign({
    secret: LOGIN_SECRET,
    sysCode: LOGIN_SYS_CODE,
    token: localStorage.getItem('token')
  });
  var param = {
    withCredentials: false,
    // 跨域请求时是否需要使用凭证
    sysCode: LOGIN_SYS_CODE,
    // 应用code
    'X-Auth-Token': localStorage.getItem('token') || '1773920873871517092',
    'x-req-userid': JSON.parse(localStorage.getItem('userId')) || '1111690474595302194',
    'x-req-merchantid': function (mchInfo) {
      return mchInfo ? function (mchInfo) {
        var info = JSON.parse(mchInfo);
        return info.mchDetailId;
      }(mchInfo) : '1685429291604311049';
    }(mchInfo),
    'x-req-merchantuserid': function (mchInfo) {
      return mchInfo ? function (mchInfo) {
        var info = JSON.parse(mchInfo);
        return info.mchUserId;
      }(mchInfo) : '1685429325964047108';
    }(mchInfo)
  };
  config.headers = _objectSpread(_objectSpread({}, param), signData);
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});
// respone拦截器
service.interceptors.response.use(function (response) {
  _Toast.clear();
  var res = response.data;
  if (res.status && res.status !== 200) {
    // 登录超时,重新登录
    if (res.status === 401) {
      store.dispatch('FedLogOut').then(function () {
        location.reload();
      });
    }
    return Promise.reject(res || 'error');
  } else {
    return Promise.resolve(res);
  }
}, function (error) {
  _Toast.clear();
  console.log('错误信息' + error); // for debug

  var url = error.response.config.url;
  //山头项目标签查询接口拦截报错
  if (url.includes('/yk/biz_pms_tag/v1/get_tags.do') || url.includes('/yk/biz_pms_tag/v1/get_tag.do')) {
    return Promise.reject('error');
  }
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 504:
        _Toast({
          message: '网络错误,请联系管理员！',
          type: 'error'
        });
        break;
      case 404:
        _Toast({
          message: '系统繁忙，稍后重试！',
          type: 'error'
        });
        break;
      default:
        // Toast({ message: '系统异常，稍后重试', type: 'error' })
        break;
    }
  } else if (JSON.stringify(error.message).includes('timeout')) {
    _Toast({
      message: '系统链接超时，请重新进入',
      type: 'error'
    });
    // 进入接口超时链接
    store.commit('app/SET_DISCONNECT', 'timeout');
  } else if (!JSON.parse(sessionStorage.getItem('online'))) {
    _Toast({
      message: '网络无法连接，请检查网络',
      type: 'error'
    });
  } else {
    // Toast({ message: '系统异常，稍后重试', type: 'error' })
  }
  return Promise.reject(error);
});
export default service;