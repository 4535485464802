import elementResizeDetectorMaker from "element-resize-detector";

/**
 * @description:监听页面宽度-高度变化
 *
 * **/
var windowResize = {
  // 使用局部注册指令的方式
  // 指令的名称
  bind: function bind(el, binding, vnode) {
    // el为绑定的元素，binding为绑定给指令的对象
    var width = "";
    // let height = "";  这就是监听高度了
    var that = vnode.context;
    function isReize() {
      var style = document.defaultView.getComputedStyle(el);
      // if (width !== style.width||height !== style.height) {  这就是监听宽度和高度了
      if (width !== style.width) {
        // binding.value();  // 关键
        var pxNumber = style.width.split("px")[0];
        that[binding.arg](binding.value, pxNumber);
      }
      width = style.width;
      // height = style.height;  这就是监听宽度和高度了
    }
    el.__vueSetInterval__ = setInterval(isReize, 300);
  },
  unbind: function unbind(el) {
    clearInterval(el.__vueSetInterval__);
  }
};
export default windowResize;