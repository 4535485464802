import "/data/shupian/qds-scrmnet-0/node_modules/core-js/modules/es.array.iterator.js";
import "/data/shupian/qds-scrmnet-0/node_modules/core-js/modules/es.promise.js";
import "/data/shupian/qds-scrmnet-0/node_modules/core-js/modules/es.object.assign.js";
import "/data/shupian/qds-scrmnet-0/node_modules/core-js/modules/es.promise.finally.js";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@vant/touch-emulator';
import Vue from 'vue';
import App from './App.vue';
import Disconnect from './components/Disconnect';
import router from './router';
import store from './store';
import init from './utils/init';
// 设置 js中可以访问 $cdn
import { $cdn } from '@/config';
// 注册指令
import directives from '@/utils/directives';
Vue.use(directives);
Vue.prototype.$cdn = $cdn;
Vue.component('Disconnect', Disconnect);
// 全局引入按需引入UI库 vant
import '@/plugins/vant';
// 引入全局样式
import '@/assets/css/index.scss';
// 移动端适配
import 'lib-flexible/flexible.js';
import '@/assets/iconFont/iconfont.css';

// 引入vconsole

// import vConsole from '@/utils/vconsole.js'
// import VConsole from 'vconsole'
// if (process.env.VUE_APP_SP_ENV !== 'production') {
//   const vConsole = new VConsole()
// }

// filters
import './filters';
Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});