export default {
  state: {
    mainAccount: undefined,
    active: 0,
    phone: undefined,
    bizId: undefined,
    clueId: undefined,
    group: undefined,
    nextFollowText: undefined,
    nextFollowTime: undefined,
    imgList: [],
    file: {},
    company: '',
    thirdCategoryCodes: [],
    memberData: undefined,
    merchantList: [],
    salesValue: 'nothing',
    memberArea: undefined,
    memberAreaIndex: undefined
  },
  mutations: {
    /**
     * @description 保存规划师自己的手机号码
     */
    saveMainAccount: function saveMainAccount(state, value) {
      state.mainAccount = value;
    },
    /**
     * @description 保存客户资料tab
     */
    saveActive: function saveActive(state, value) {
      state.active = value;
    },
    clearActive: function clearActive(state, value) {
      state.active = 0;
    },
    /**
     * @description 保存转商机手机号
     */
    savePhone: function savePhone(state, value) {
      state.phone = value;
    },
    clearPhone: function clearPhone(state, value) {
      state.phone = undefined;
    },
    /**
     * @description 保存商机ID
     */
    saveBizId: function saveBizId(state, value) {
      state.bizId = value;
    },
    /**
     * @description 保存线索ID
     */
    saveClueId: function saveClueId(state, value) {
      state.clueId = value;
    },
    /**
     * @description 保存分组信息
     */
    saveGroup: function saveGroup(state, value) {
      state.group = value;
    },
    clearGroup: function clearGroup(state, value) {
      state.group = undefined;
    },
    /**
     * @description 保存下次跟进内容
     */
    saveNextFollowText: function saveNextFollowText(state, value) {
      state.nextFollowText = value;
    },
    clearNextFollowText: function clearNextFollowText(state, value) {
      state.nextFollowText = undefined;
    },
    /**
     * @description 保存下次跟进时间
     */
    saveNextFollowTime: function saveNextFollowTime(state, value) {
      state.nextFollowTime = value;
    },
    clearNextFollowTime: function clearNextFollowTime(state, value) {
      state.nextFollowTime = undefined;
    },
    /**
     * @description 保存上传截图
     */
    saveImgList: function saveImgList(state, value) {
      state.imgList = value;
    },
    clearImgList: function clearImgList(state, value) {
      state.imgList = [];
    },
    /**
     * @description 保存上传截图的信息
     */
    saveFile: function saveFile(state, value) {
      state.file = value;
    },
    clearFile: function clearFile(state, value) {
      state.file = {};
    },
    /**
     * @description 保存用户选择的公司名称
     */
    saveCompany: function saveCompany(state, value) {
      state.company = value;
    },
    clearCompany: function clearCompany(state, value) {
      state.company = '';
    },
    /**
     * @description 保存企业购筛选参数
     */
    saveMemberData: function saveMemberData(state, value) {
      state.memberData = value;
    },
    clearMemberData: function clearMemberData(state, value) {
      state.memberData = undefined;
    },
    /**
     * @description 保存企业购城市筛选
     */
    saveMemberArea: function saveMemberArea(state, value) {
      state.memberArea = value;
    },
    clearMemberArea: function clearMemberArea(state, value) {
      state.memberArea = undefined;
    },
    /**
     * @description 保存企业购城市筛选index
     */
    saveMemberAreaIndex: function saveMemberAreaIndex(state, value) {
      state.memberAreaIndex = value;
    },
    clearMemberAreaIndex: function clearMemberAreaIndex(state, value) {
      state.memberAreaIndex = undefined;
    },
    /**
     * @description 保存商户列表
     */
    saveMerchantList: function saveMerchantList(state, value) {
      state.merchantList = value;
    },
    clearMerchantList: function clearMerchantList(state, value) {
      state.merchantList = [];
    },
    /**
     * @description 保存是否授权
     */
    saveSalesValue: function saveSalesValue(state, value) {
      state.salesValue = value;
    },
    clearSalesValue: function clearSalesValue(state, value) {
      state.salesValue = 'nothing';
    },
    /**
     * @description 保存选中产品3级code
     */
    saveThirdCategoryCodes: function saveThirdCategoryCodes(state, value) {
      state.thirdCategoryCodes = value;
    },
    clearThirdCategoryCodes: function clearThirdCategoryCodes(state, value) {
      state.thirdCategoryCodes = [];
    }
  }
};