import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import test from './modules/test';
import saveTabsActive from './modules/save-tabs-active';
import marketRouteStore from './modules/marketRouteStore';
import writeFollowStore from './modules/write-follow';
Vue.use(Vuex);
var store = new Vuex.Store({
  getters: getters,
  modules: {
    app: app,
    test: test,
    saveTabsActive: saveTabsActive,
    marketRouteStore: marketRouteStore,
    writeFollowStore: writeFollowStore
  }
});
export default store;