import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
/**
 * @description:配置产线初始化需要请求的接口
 * @params:请求wx.invoke必须参数
 * **/
import { getAccessFirm, getAccessToekn } from './initConfig';
import { Secret, baseApi, addressUrl } from '@/config';
import axios from 'axios';
import { login } from '@/api/user';
import { GetRequest } from '@/utils/index';
var Base64 = require('js-base64').Base64;
export var initApi = {
  /**config注入的是企业的身份与权限**/getConfig: function getConfig() {
    var corpId = sessionStorage.getItem('corpId');
    var AgentId = sessionStorage.getItem('AgentId');
    return new Promise(function (resolve) {
      //
      getAccessFirm().then(function (res) {
        console.log('企业数据', res);
        var data = {
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.noncestr,
          signature: res.data.data.signature,
          corpId: corpId,
          AgentId: AgentId
        };
        resolve(data);
      });
    });
  },
  /**agentConfig注入的是应用的身份与权限**/getAgentConfig: function getAgentConfig() {
    var corpId = sessionStorage.getItem('corpId');
    var AgentId = sessionStorage.getItem('AgentId');
    return new Promise(function (resolve) {
      getAccessToekn().then(function (res) {
        console.log('应用数据', res);
        var data = {
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.noncestr,
          signature: res.data.data.signature,
          corpId: corpId,
          AgentId: AgentId
        };
        sessionStorage.setItem('agentConfig', JSON.stringify(data));
        resolve(data);
      });
    });
  }
};