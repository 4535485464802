/**
 * @description:此配置文件，用于配置各个不同企微主体(目前已顶呱呱作为主要企微主体)
 * 用于配置企微需要手动授权后的企微主体（顶呱呱之前都是静默授权）
 * 用于产线配置
 * **/
var wrchatCropIdList = [{
  name: '浙江良驹律师事务所',
  corpId: 'wwbcc57ce621e6e190',
  url: 'https://m-crm.zjljls.cn',
  tUrl: 'https://tm-crm.zjljls.cn',
  pUrl: 'https://pm-crm.zjljls.cn'
}, {
  name: '北京顶言律师事务所',
  corpId: 'wwbd1d249b93791ce3',
  url: 'https://m-crm.bjdyls.cn',
  tUrl: 'https://tm-crm.bjdyls.cn',
  pUrl: 'https://pm-crm.bjdyls.cn'
}, {
  name: '广东莞升律师事务所',
  corpId: 'wwee210e03e874d70c',
  url: 'https://m-crm.gdgsls.cn',
  tUrl: 'https://tm-crm.gdgsls.cn',
  pUrl: 'https://pm-crm.gdgsls.cn'
}, {
  name: '重庆盈兴律师事务所',
  corpId: 'ww4e8105e5522f252d',
  url: 'https://m-crm.cqyxls.cn',
  tUrl: 'https://tm-crm.cqyxls.cn',
  pUrl: 'https://pm-crm.cqyxls.cn'
}];
module.exports = wrchatCropIdList;