export default {
  namespaced: true,
  state: {
    imgList: [],
    files: []
  },
  mutations: {
    /**
     * @description 保存本地图片
     */
    setWriteFollowImg: function setWriteFollowImg(state, value) {
      state.imgList = value;
    },
    /**
     * @description 保存网上图片
     */
    setFiles: function setFiles(state, value) {
      state.files = value;
    }
  }
};