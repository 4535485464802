export default {
  state: {
    path: null,
    //路由
    query: null,
    //参数
    state: 1,
    //标识
    agintState: 0
  },
  mutations: {
    saveActive: function saveActive(state, value) {
      state.active = value;
    },
    //存储跳转路径和参数
    savePathState: function savePathState(state, value) {
      state.state = value.state;
    },
    //存储初始化应用位置
    initAgintLocal: function initAgintLocal(state, value) {
      state.agintState = value.agintState;
    }
  }
};