var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.disconnect,
      expression: "disconnect"
    }],
    style: {
      height: "100vh",
      padding: "0px 0 0",
      boxSizing: "border-box",
      background: "#fff;"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.disconnect == "noConnected",
      expression: "disconnect == 'noConnected'"
    }],
    staticClass: "disconnect"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/image/default_img_nointernet.png")
    }
  }), _c("div", [_vm._v("网络未开启，请检查网络设置")]), _c("div", {
    staticClass: "btn",
    on: {
      click: _vm.refresh
    }
  }, [_vm._v("重新加载")])], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.disconnect === "timeout",
      expression: "disconnect === 'timeout'"
    }],
    staticClass: "disconnect"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("van-image", {
    attrs: {
      src: require("@/assets/image/default_img_requestAborted.png"),
      mode: "aspectFit"
    }
  }), _c("div", [_vm._v("请求失败,请重试")]), _c("div", {
    staticClass: "btn",
    on: {
      click: _vm.refresh
    }
  }, [_vm._v("重新加载")])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };